import React, { useRef } from 'react';
import "./contact.css";
import { HiOutlineMail, HiOutlineArrowSmRight, HiPhone } from "react-icons/hi";
import { FaWhatsapp } from "react-icons/fa";

import emailjs from '@emailjs/browser';

import { personal } from '../../data/personal';

import Send from "../../assets/send.svg";

const Contact = () => {
    const form = useRef();
    
    const sendEmail = (e) => {
      console.log(process.env.REACT_APP_PUBLIC_KEY);
        e.preventDefault();
    
        emailjs.sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_PUBLIC_KEY
        );
        e.target.reset();
    };
  
    return (
      <section className="contact section" id="contact">
        <h2 className="section__title">Let's Connect</h2>
        <span className="section__subtitle">
          Ready to bring your vision to life? Contact me today for tailored
          solutions.
        </span>

        <div className="contact__container container grid">
          <div className="contact__content">
            <h3 className="contact__title">Talk to me</h3>

            <div className="contact__info">
              <div className="contact__card">
                <HiOutlineMail className="contact__card-icon" />

                <h3 className="contact__card-title">Email</h3>
                <span className="contact__card-data">{personal.email}</span>

                <a
                  href={"mailto:" + personal.email}
                  className="contact__button"
                  target="_blank"
                  rel="noreferrer"
                >
                  Write Me{" "}
                  <HiOutlineArrowSmRight className="contact__button-icon" />
                </a>
              </div>
              <div className="contact__card">
                <HiPhone className="contact__card-icon" />

                <h3 className="contact__card-title">Phone</h3>
                <span className="contact__card-data">{personal.phone}</span>

                <a
                  href={"tel:" + personal.phone}
                  className="contact__button"
                  target="_blank"
                  rel="noreferrer"
                >
                  Write Me{" "}
                  <HiOutlineArrowSmRight className="contact__button-icon" />
                </a>
              </div>
              <div className="contact__card">
                <FaWhatsapp className="contact__card-icon" />

                <h3 className="contact__card-title">WhatsApp</h3>
                <span className="contact__card-data">{personal.whatsapp}</span>

                <a
                  href={"https://wa.me/" + personal.whatsapp}
                  className="contact__button"
                  target="_blank"
                  rel="noreferrer"
                >
                  Write Me{" "}
                  <HiOutlineArrowSmRight className="contact__button-icon" />
                </a>
              </div>
            </div>
          </div>

          <div className="contact__content">
            <h3 className="contact__title">What's the project?</h3>

            <form ref={form} onSubmit={sendEmail} className="contact__form">
              <div className="contact__form-div">
                <label className="contact__form-tag">Name</label>
                <input
                  type="text"
                  name="name"
                  className="contact__form-input"
                  placeholder="Type your name"
                />
              </div>

              <div className="contact__form-div">
                <label className="contact__form-tag">Email</label>
                <input
                  type="email"
                  name="email"
                  className="contact__form-input"
                  placeholder="Type your email"
                />
              </div>

              <div className="contact__form-div contact__form-area">
                <label className="contact__form-tag">Project</label>
                <textarea
                  name="message"
                  cols="30"
                  rows="10"
                  className="contact__form-input"
                  placeholder="Provide some project details..."
                ></textarea>
              </div>

              <button href="#contact" className="button button--flex">
                Send Message
                <img src={Send} className="button__icon" alt="" />
              </button>
            </form>
          </div>
        </div>
      </section>
    );
}

export default Contact;