import React, { useState } from 'react';
import './services.css';
import { HiOutlineClipboardList, HiOutlineArrowSmRight, HiOutlineCheckCircle, HiX } from 'react-icons/hi';

import {services} from "../../data/services";

const Services = () => {
    const [toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
      <section className="services section" id="services">
        <h2 className="section__title">Services</h2>
        <span className="section__subtitle">
          Empowering businesses with seamless web and mobile solutions and
          expert system design.
        </span>

        <div className="services__container container grid">
          {services.map((service, ind) => (
            <div className="services__content" key={service.name}>
              <div>
                <HiOutlineClipboardList className="services__icon" />
                <h3 className="services__title">
                  <div dangerouslySetInnerHTML={{ __html: service.name }} />
                </h3>
              </div>
              <span className="services__button" onClick={() => toggleTab(ind + 1)}>
                View More
                <HiOutlineArrowSmRight className="services__button-icon" />
              </span>
              <div
                className={
                  toggleState === ind + 1
                    ? "services__modal active-modal"
                    : "services__modal"
                }
              >
                <div className="services__modal-content">
                  <HiX
                    onClick={() => toggleTab(0)}
                    className="services__modal-close"
                  />
                  <h3 className="services__modal-title">{service.clean_name}</h3>
                  <p className="services__modal-description">
                    {service.description}
                  </p>
                  <ul className="services__modal-services grid">
                    {service.items.map((item) => (
                      <li className="services__modal-service" key={item}>
                        <HiOutlineCheckCircle className="services__modal-icon" />
                        <p className="services__modal-info">
                          {item}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
}

export default Services;