import React from "react";
import "./footer.css";
import { socials } from "../../data/social";

const Footer = () => {
    console.log(socials);
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">Rajan Gautam</h1>
        <div className="footer__social">
          {socials.map((social) => (
            <a
              key={social.link}
              href={social.link}
              className="home__social-icon"
              target="_blank"
              rel="noreferrer"
            >
              {social.Icon}
            </a>
          ))}
        </div>
        <span className="footer__copy"></span>
      </div>
    </footer>
  );
};

export default Footer;
