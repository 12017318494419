import CrediManager from "../../assets/CrediManager.png";
import RealEstate from "../../assets/RealEstate.png";
import Snaphome from "../../assets/Snaphome.png";
import VotingApplication from "../../assets/VotingApplication.png";
import MotoApp from "../../assets/MotoApp.png";
import SocialApp from "../../assets/SocialApp.png";
import RMDB from "../../assets/RMDB.png";
import HotelManagement from "../../assets/HotelManagement.webp";

export const projectsData = [
  {
    image: CrediManager,
    title: "Credit Manager",
    category: "Full Stack",
    link: "https://github.com/rgautam320/CrediManager",
  },
  {
    image: RealEstate,
    title: "Real Estate",
    category: "Full Stack",
    link: "https://github.com/rgautam320/Real-Estate",
  },
  {
    image: Snaphome,
    title: "Snaphome",
    category: "Frontend",
    link: "https://github.com/rgautam320/React-Projects/tree/master/Snaphome",
  },
  {
    image: VotingApplication,
    title: "Voting Application",
    category: "Full Stack",
    link: "https://github.com/rgautam320/Voting-System",
  },
  {
    image: MotoApp,
    title: "MotoApp",
    category: "Full Stack",
    link: "https://github.com/rgautam320/MotoApp",
  },
  {
    image: SocialApp,
    title: "Social App",
    category: "Full Stack",
    link: "https://github.com/rgautam320/Social-App",
  },
  {
    image: RMDB,
    title: "RMDB",
    category: "Full Stack",
    link: "https://github.com/rgautam320/Next-Projects/tree/master/RMDB",
  },
  {
    image: HotelManagement,
    title: "Hotel Management System",
    category: "Backend",
    link: "https://github.com/rgautam320/APIs-in-dotNET/tree/hotel-booking",
  },
];

export const projectsNav = [
    {
        name: "All",
    },
    {
        name: "Full Stack"
    },
    {
        name: "Frontend",
    },
    {
        name: "Backend",
    },
];