import React from 'react';
import { HiOutlineArrowSmRight } from "react-icons/hi";

const ProjectItems = ({item}) => {
  return (
    <div className="project__card" key={item.name}>
      <img className="project__img" src={item.image} alt="" />
      <h3 className="project__title">{item.title}</h3>
      <a
        href={item.link}
        target="_blank"
        className="project__button"
        rel="noreferrer"
      >
        Demo <HiOutlineArrowSmRight className="project__button-icon" />
      </a>
    </div>
  );
}

export default ProjectItems;