import React from 'react';
import { personal } from '../../data/personal';

import Send from "../../assets/send.svg";
import Hand from "../../assets/hand.svg";

const Data = () => {
  return (
    <div className="home__data">
      <h1 className="home__title">
        {personal.name}
        <img className="home__hand" src={Hand} alt='' />
      </h1>
      <h3 className="home__subtitle">Senior Software Engineer</h3>
      <p className="home__description">
        Engineering excellence across the entire stack.
      </p>

      <a href="#contact" className="button button--flex">
        Say Hello
        <img className='button__icon' src={Send} alt='' />
      </a>
    </div>
  );
}

export default Data;