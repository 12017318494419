import React from 'react';
import Projects from './Projects';
import "./projects.css";

const Portfolio = () => {
  return (
    <section className="portfolio section" id="portfolio">
      <h2 className="section__title">Personal Portfolio</h2>
      <span className="section__subtitle">
        Pushing boundaries with every project.
      </span>

      <Projects />
    </section>
  );
}

export default Portfolio;