import React from 'react';
import "./skills.css";
import { skills } from '../../data/skills';
import Skill from './SkillComponent';

const Skills = () => {
  return (
    <section className="skills section" id="skills">
      <h2 className="section__title">Skills</h2>
      <span className="section__subtitle">
        Below are my skills for powering digital innovation with expertise
        across frontend, backend, and mobile development.
      </span>
      <div className="skills__container container grid">
        <Skill name="Frontend Developer" skills={skills.frontend} />
        <Skill name="Backend Developer" skills={skills.backend} />
        <Skill name="Frontend Developer" skills={skills.language} />
        <Skill name="Others" skills={skills.other} />
      </div>
    </section>
  );
}

export default Skills;