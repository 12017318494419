import React from "react";
import { socials } from "../../data/social";

const Social = () => {
    return (
      <div className="home__social">
        {socials.map((social) => (
          <a
            key={social.link}
            href={social.link}
            className="home__social-icon"
            target="_blank"
            rel="noreferrer"
          >
            {social.Icon}
          </a>
        ))}
      </div>
    ); 
}

export default Social;