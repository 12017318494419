import Image1 from "../../assets/woman1.png";
import Image2 from "../../assets/man.png";
import Image3 from "../../assets/woman2.png";

export const Data = [
  {
    id: 1,
    image: Image1,
    title: "Emily J.",
    description:
      "Outstanding service! His expertise transformed our project into a success.",
  },
  {
    id: 2,
    image: Image2,
    title: "Thabo N.",
    description: "Highly recommended! He delivered beyond our expectations.",
  },
  {
    id: 3,
    image: Image3,
    title: "Anna Müller",
    description: "Exceptional team! Professional, innovative, and dedicated.",
  },
  {
    id: 4,
    image: Image2,
    title: "Ravi K.",
    description:
      "A pleasure to work with! Their solutions have truly made a difference.",
  },
];