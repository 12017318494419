import React from 'react';
import "./about.css";
import CV from "../../assets/Rajan_Gautam_Resume.pdf";
import Info from "./Info";

import FileIcon from "../../assets/files.svg"

const About = () => {
  return (
    <section className="about section" id="about">
      <h2 className="section__title">About Me</h2>
      <span className="section__subtitle">
        A Senior Software Engineer skilled in frontend, backend and mobile
        application development, dedicated to crafting seamless and scalable
        digital solutions.
      </span>
      <div className="about__container container grid">
        <img src="./Rajan.png" alt="about" className="about__img" />
        <div className="about__data">
          <Info />
          <p className="about__description">
            Specialize in delivering innovative, minimalistic, and user-friendly
            web and mobile applications.
          </p>
          <a download="" href={CV} className="button button--flex">
            Download CV
            <img className="about__icon" src={FileIcon} alt='' />
          </a>
        </div>
      </div>
    </section>
  );
}

export default About;