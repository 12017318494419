import { FiTwitter, FiGithub, FiLinkedin } from "react-icons/fi";

export const socials = [
  {
    link: "https://github.com/rgautam320",
    Icon: <FiGithub />,
  },
  {
    link: "https://linkedin.com/in/rgautam320",
    Icon: <FiLinkedin />,
  },
  {
    link: "https://x.com/rgautam320",
    Icon: <FiTwitter />,
  },
];
