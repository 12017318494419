import React from "react";
import { HiCheckBadge } from "react-icons/hi2";

const Skill = ({name, skills}) => {
    return (
      <div className="skills__content">
        <h3 className="skills__title">{name}</h3>
        <div className="skills__box">
          <div className="skills__group grid">
            {skills.map((skill) => (
              <div className="skills__data" key={skill.skill}>
                <HiCheckBadge />
                <div>
                  <h3 className="skills__name">{skill.skill}</h3>
                  <span className="skills__level">{skill.level}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
}

export default Skill;