import React from 'react';
import {HiOutlineArrowSmDown} from "react-icons/hi";

import ScrollIcon from "../../assets/scroll.svg";

const Scroll = () => {
  return (
    <div className="home__scroll">
        <a href="#about" className="home__scroll-button button--flex">
            <img src={ScrollIcon} alt='' />
            <span className="home__scroll-name">Scroll Down</span>
            <HiOutlineArrowSmDown className="home__scroll-arrow"/>
        </a>
    </div>
  )
}

export default Scroll;