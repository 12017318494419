export const skills = {
  frontend: [
    {
      skill: "React/Next",
      level: "Advance",
    },
    {
      skill: "React Native",
      level: "Advance",
    },
    {
      skill: "JavaScript",
      level: "Advance",
    },
    {
      skill: "PHP",
      level: "Advance",
    },
    {
      skill: "Vue",
      level: "Intermediate",
    },
    {
      skill: "Tailwind",
      level: "Intermediate",
    },
  ],
  backend: [
    {
      skill: "Node",
      level: "Advance",
    },
    {
      skill: "Express",
      level: "Advance",
    },
    {
      skill: "Laravel",
      level: "Advance",
    },
    {
      skill: ".NET",
      level: "Advance",
    },
    {
      skill: "Django",
      level: "Intermediate",
    },
    {
      skill: "Spring",
      level: "Intermediate",
    },
  ],
  language: [
    {
      skill: "C/C++",
      level: "Advance",
    },
    {
      skill: "JavaScript",
      level: "Advance",
    },
    {
      skill: "PHP",
      level: "Advance",
    },
    {
      skill: "C#",
      level: "Advance",
    },
    {
      skill: "Python",
      level: "Intermediate",
    },
    {
      skill: "Java",
      level: "Intermediate",
    },
  ],
  other: [
    {
      skill: "SQL",
      level: "Advance",
    },
    {
      skill: "MongoDB",
      level: "Advance",
    },
    {
      skill: "Docker",
      level: "Intermediate",
    },
    {
      skill: "Azure",
      level: "Intermediate",
    },
    {
      skill: "Windows",
      level: "Advanced",
    },
    {
      skill: "Mac/Ubuntu",
      level: "Advanced",
    }
  ],
};