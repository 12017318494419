export const services = [
  {
    name: "Web <br /> Developer",
    clean_name: "Web Developer",
    description:
      "Over 3 years of experience in web development providing quality work.",
    items: [
      "Expertise in frontend, backend, and database technologies.",
      "Focus on creating intuitive and visually appealing interfaces.",
      "Experience in developing robust and scalable web applications.",
      "Efficient project delivery using agile methodologies.",
      "Specialization in responsive design for seamless user experiences across devices.",
    ],
  },
  {
    name: "Mobile <br /> Developer",
    clean_name: "Mobile Developer",
    description:
      "Over 3 years of experience in mobile app development providing quality work.",
    items: [
      "Expertise in React Native development.",
      "Focus on creating intuitive and engaging mobile interfaces.",
      "Experience in optimizing app performance for speed and responsiveness.",
      "Proficiency in agile methodologies for efficient app delivery and iterative improvements.",
      "Skills in building cross-platform apps with frameworks like React Native or Flutter for wider reach and efficiency.",
    ],
  },
  {
    name: "System <br /> Designer",
    clean_name: "System Designer",
    description:
      "Over 2 years of experience in system design.",
    items: [
      "Designing scalable system architectures to meet business requirements.",
      "Creating efficient database schemas for data integrity and performance.",
      "Implementing seamless system and database integrations for enhanced interoperability.",
    ],
  },
];